import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstanceEdl } from "../../utils/axiosEdl";
import { logout } from "../auth";

export const getEdlList = createAsyncThunk(
    "getEdlList",
    async (
        { listPage: page, filters: userFilters },
        { rejectWithValue, dispatch }
    ) => {
        let search = userFilters
            ? userFilters.search
                ? userFilters.search
                : ""
            : "";
        let active = userFilters
            ? userFilters.active !== null
                ? userFilters.active
                : ""
            : "";
        let list_type = userFilters
            ? userFilters.list_type !== null
                ? userFilters.list_type
                : ""
            : "";
        try {
            let url = `/api/v1/edl/lists/?page=${page}&search=${search}`;
            if (active !== "") url = url + `&is_active=${active}`;
            if (list_type !== "") url = url + `&list_type=${list_type}`;
            const { data } = await axiosInstanceEdl.get(url, {
                headers: {
                    "edl-access-token":
                        "Token " + sessionStorage.getItem("edlTokenPC"),
                    "parus-access-token":
                        "Bearer " + sessionStorage.getItem("accessTokenPC"),
                },
            });
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const addEdlList = createAsyncThunk(
    "addEdlList",
    async (data, { rejectWithValue, dispatch }) => {
        const params = data;
        try {
            const { data } = await axiosInstanceEdl.post(
                `/api/v1/edl/lists/`,
                params,
                {
                    // headers: {
                    //     "Content-Type": "application/json",
                    // },
                    onUploadProgress: (progressEvent) => {
                        const progress =
                            (progressEvent.loaded / progressEvent.total) * 100;
                        dispatch(setProgress(progress));
                    },
                    onDownloadProgress: (progressEvent) => {
                        const progress =
                            (progressEvent.loaded / progressEvent.total) * 100;

                        dispatch(setProgress(progress));
                    },
                }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const patchEdlList = createAsyncThunk(
    "patchEdlList",
    async (
        { isActive, idList: id, user: patchUser },
        { rejectWithValue, dispatch }
    ) => {
        const params = {};
        params.is_active = isActive;
        params.updated_by = patchUser;
        try {
            const { data } = await axiosInstanceEdl.patch(
                `/api/v1/edl/lists/${id}/set_is_active/`,
                params
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const checkEdlListValidators = createAsyncThunk(
    "checkEdlListValidators",
    async ({ idList: id, validators }, { rejectWithValue, dispatch }) => {
        const params = {};
        params.validators=validators
        try {
            const { data } = await axiosInstanceEdl.patch(
                `/api/v1/edl/lists/${id}/check_new_validators/`,
                params
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const applyEdlListValidators = createAsyncThunk(
    "applyEdlListValidators",
    async (
        { idList: id, body },
        { rejectWithValue, dispatch }
    ) => {
        const parametres = body;
       
        try {
            const { data } = await axiosInstanceEdl.patch(
                `/api/v1/edl/lists/${id}/apply_new_validators/`,
                parametres, { params: {
                    list_id: id
                  }}
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const deleteEdlList = createAsyncThunk(
    "deleteEdlList",
    async ({ idList: id, userUpdated }, { rejectWithValue, dispatch }) => {
        const params = {};
        if (userUpdated?.first_name || userUpdated?.last_name)
            params.updated_by = `${userUpdated?.first_name} ${userUpdated?.last_name}`;
        else params.updated_by = userUpdated?.email;
        try {
            const { data } = await axiosInstanceEdl.delete(
                `/api/v1/edl/lists/${id}/`,
                { data: params }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const getEdlUser = createAsyncThunk(
    "getEdlUser",
    async (userUrl, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(
                `/api/v1/edl/users/me/`
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getEdlDetailInstance = createAsyncThunk(
    "getEdlDetailInstance",
    async (
        { idEdl: id, pageInstance: page, searchText: search },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const { data } = await axiosInstanceEdl.get(
                `/api/v1/edl/lists/${id}/instances/?page=${page}&search=${search}`
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getEdlDetailList = createAsyncThunk(
    "getEdlDetailList",
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(
                `/api/v1/edl/lists/${id}/`,
                {
                    headers: {
                        "edl-access-token":
                            "Token " + sessionStorage.getItem("edlTokenPC"),
                        "parus-access-token":
                            "Bearer " + sessionStorage.getItem("accessTokenPC"),
                    },
                }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getFileList = createAsyncThunk(
    "getFileList",
    async ({ url, name, id }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(`/api/v1/edl/lists/${id}/download/`, 
            // {
            //     params: { url: url },
            // }
            );
            return { data, name };
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getFile = createAsyncThunk(
    "getFile",
    async ({ url, name }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(`/api/v1/edl/files/`, {
                params: { url: url },
            });
            return { data, name };
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const addEdlInstance = createAsyncThunk(
    "addEdlInstance",
    async (
        { data, idList: id, typeList: type },
        { rejectWithValue, dispatch }
    ) => {
        const params = data;
        try {
            const { data } = await axiosInstanceEdl.post(
                type === "value"
                    ? `/api/v1/edl/lists/${id}/add_instance/`
                    : `/api/v1/edl/lists/${id}/add_instances_file/`,
                params,
                {
                    // headers: {
                    //     "Content-Type": "application/json",
                    // },
                    onUploadProgress: (progressEvent) => {
                        const progress =
                            (progressEvent.loaded / progressEvent.total) * 100;
                        dispatch(setProgress(progress));
                    },
                    onDownloadProgress: (progressEvent) => {
                        const progress =
                            (progressEvent.loaded / progressEvent.total) * 100;

                        dispatch(setProgress(progress));
                    },
                }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const deleteEdlInstance = createAsyncThunk(
    "deleteEdlInstance",
    async (
        { idInstance: idIn, idList: id, userUpdated },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const params = {};
            if (userUpdated?.first_name || userUpdated?.last_name)
                params.updated_by = `${userUpdated?.first_name} ${userUpdated?.last_name}`;
            else params.updated_by = userUpdated?.email;
            const { data } = await axiosInstanceEdl.delete(
                `/api/v1/edl/lists/${id}/instances/${idIn}/`,
                { data: params }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const patchEdlInstance = createAsyncThunk(
    "patchEdlInstance",
    async (
        { data: params, idInstance: idIn, idList: id },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const { data } = await axiosInstanceEdl.patch(
                `/api/v1/edl/lists/${id}/instances/${idIn}/`,
                params
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const patchEdlUser = createAsyncThunk(
    "patchEdlUser",
    async ({password, updated_by}, { rejectWithValue, dispatch }) => {
        try {
            const params = {};
            params.device_password = password;
            params.updated_by = updated_by
            const { data } = await axiosInstanceEdl.patch(
                `/api/v1/edl/users/me/`,
                params
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);

export const getTaskList = createAsyncThunk(
    "getTaskList",
    async ({ page: activePage, filter: statusFilter }) => {
        const actualPage = activePage ? activePage : null;
        const { data } = await axiosInstanceEdl.get(
            "api/v1/edl/celery-tasks/",
            {
                params: {
                    page: actualPage,
                    status: statusFilter,
                },
            }
        );
        return data;
    }
);
export const getTaskDetails = createAsyncThunk(
    "getTaskDetails",
    async (taskId) => {
        const params = {};
        const { data } = await axiosInstanceEdl.get(
            `api/v1/edl/celery-tasks/${taskId}/`,
            params
        );
        return data;
    }
);

export const getCheckListName = createAsyncThunk(
    "getCheckListName",
    async ({ name, dataList }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(`/api/v1/edl/lists/`, {
                params: { name: name },
                headers: {
                    "edl-access-token":
                        "Token " + sessionStorage.getItem("edlTokenPC"),
                    "parus-access-token":
                        "Bearer " + sessionStorage.getItem("accessTokenPC"),
                },
            });
            if (data.count === 0) dispatch(addEdlList(dataList));
            else throw new Error({ response: "alarma" });
        } catch (err) {
            if (!err?.response)
                return rejectWithValue({
                    detail: "Список с таким названием уже существует!",
                });
            else {
                if (err?.response.status === 401) dispatch(logout());
                else return rejectWithValue(err.response.data);
            }
        }
    }
);
export const getCheckListsLimit = createAsyncThunk(
    "getCheckListsLimit",
    async ({ name, dataList }, { rejectWithValue, dispatch }) => {
        try {
            await axiosInstanceEdl.get(`/api/v1/edl/celery-tasks/check-limit/`);
            dispatch(getCheckListName({ name: name, dataList: dataList }));
        } catch (err) {
            if (!err?.response.status === 400)
                return rejectWithValue({
                    detail: "Действие на данный момент невозможно, так как превышен лимит фоновых задач (3 штук). Попробуйте чуть позже.",
                });
            else {
                if (err?.response.status === 401) dispatch(logout());
                else return rejectWithValue(err.response.data);
            }
        }
    }
);
export const getCheckListsInstance = createAsyncThunk(
    "getCheckListsInstance",
    async ({ data, idList, typeList }, { rejectWithValue, dispatch }) => {
        try {
            await axiosInstanceEdl.get(
                `/api/v1/edl/celery-tasks/check-limit/`,
                { params: { list_id: idList } }
            );
            dispatch(
                addEdlInstance({
                    data: data,
                    idList: idList,
                    typeList: typeList,
                })
            );
        } catch (err) {
            // if (!err?.response.status === 400) return rejectWithValue({ detail: 'Действие на данный момент невозможно, так как превышен лимит фоновых задач (3 штук). Попробуйте чуть позже.' })
            // else {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getCheckCloseInstance = createAsyncThunk(
    "getCheckCloseInstance",
    async (
        { idEdl, pageInstance, searchText },
        { rejectWithValue, dispatch }
    ) => {
        try {
            await axiosInstanceEdl.get(
                `/api/v1/edl/celery-tasks/check-limit/`,
                { params: { list_id: idEdl } }
            );
            dispatch(getEdlDetailInstance({ idEdl, pageInstance, searchText }));
        } catch (err) {
            // if (!err?.response.status === 400) return rejectWithValue({ detail: 'Действие на данный момент невозможно, так как превышен лимит фоновых задач (3 штук). Попробуйте чуть позже.' })
            // else {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getCheckPlatformsLimit = createAsyncThunk(
    "getCheckPlatformsLimit ",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(
                `/api/v1/edl/users/me/check_platform_limits/`,
                {
                    headers: {
                        "edl-access-token":
                            "Token " + sessionStorage.getItem("edlTokenPC"),
                        "parus-access-token":
                            "Bearer " + sessionStorage.getItem("accessTokenPC"),
                    },
                }
            );
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getExternalList = createAsyncThunk(
    "getExternalList",
    async (page, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstanceEdl.get(
                `/api/v1/edl/lists/external_lists/?page=${page}`,
                {}
            );
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const addExternalList = createAsyncThunk(
    "addExternalList",
    async (id, { rejectWithValue, dispatch }) => {
        const params = {};

        try {
            const { data } = await axiosInstanceEdl.post(
                `/api/v1/edl/lists/external_lists/${id}/add/`,
                params
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);

const initialState = {
    edlDataLists: null,
    edlDataInstance: null,
    edlDataDetailList: null,
    edlDataUser: null,
    edlPlatformLimitWarning: null,
    listPage: null,
    instancePage: null,
    taskList: [],
    countTaskList: 0,
    taskDetails: [],
    externalData: null,
    validatorsData: null,

    progress: 0,

    errors: {
        addError: null,
        patchError: null,
        getError: null,
        getTaskError: null,
        list: null,
        details: null,
        addErrorInstance: null,
        patchUser: null,
        external: null,
        patch: null,
        validatorsError: null,
    },

    loaders: {
        list: false,
        common: false,
        add: false,
        details: false,
        external: false,
        patchUser: false,
        instance: false,
        delete: false,
        validatorsLoader: false,
    },
    success: {
        list: false,
        details: false,
        delete: false,
        patch: false,
        patchUser: false,
        add: false,
        addList: false,
        activate: false,
        deleteList: false,
        addExternal: false,
        validatorsSuccess: false,
        validatorsApplySuccess: false,
    },
};

const edlSlice = createSlice({
    name: "edl",
    initialState,
    reducers: {
        setProgress: (state, { payload }) => {
            state.progress = payload;
        },
        switchPatchSuccess: (state) => {
            state.success.patch = false;
        },
        switchDeleteSuccess: (state) => {
            state.success.delete = false;
        },
        switchDeleteListSuccess: (state) => {
            state.success.deleteList = false;
        },
        switchAddSuccess: (state) => {
            state.success.add = false;
        },
        switchAddListSuccess: (state) => {
            state.success.addList = false;
        },
        switchAсtivateListSuccess: (state) => {
            state.success.activate = false;
        },
        clearEdlErrors: (state) => {
            state.errors.addError = null;
            state.errors.patchError = null;
            state.errors.addErrorInstance = null;
            state.errors.getError = null;
        },
        switchValidateSuccess: (state) => {
            state.success.validatorsSuccess = false;
        },
        switchValidateApplySuccess: (state) => {
            state.success.validatorsApplySuccess = false;
        },
        resetValidatorsError: (state) => {
            state.errors.validatorsError = null;
        },
    },

    extraReducers: (builder) => {
        //getEdlList
        builder.addCase(getEdlList.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getEdlList.fulfilled, (state, { payload }) => {
            state.edlDataLists = payload;
            state.loaders.common = false;
            state.errors.getError = null;
        });
        builder.addCase(getEdlList.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors.getError =
                action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //addEdlList
        builder.addCase(addEdlList.pending, (state) => {
            state.loaders.add = true;
        });
        builder.addCase(addEdlList.fulfilled, (state, { payload }) => {
            state.success.addList = payload?.message || true;
            state.loaders.add = false;
            state.errors.addError = null;
        });
        builder.addCase(addEdlList.rejected, (state, action) => {
            state.loaders.add = false;
            state.errors.addError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //getFile
        builder.addCase(getFile.pending, (state) => {
            state.loaders.common = true;
            state.loaders.external = true;
            state.errors.getError = null;
        });
        builder.addCase(getFile.fulfilled, (state, { payload }) => {
            const blob = new Blob([payload.data]);
            const fileUrl = URL.createObjectURL(blob);
            const date = new Date()
                .toISOString()
                .replace(".", "-")
                .split("T")[0];
            const link = document.createElement("a");
            link.download = `${date}-${payload.name}`;
            link.href = fileUrl;
            link.click();
            state.loaders.common = false;
            state.loaders.external = false;
        });
        builder.addCase(getFile.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
            state.loaders.external = false;
        });
                //getFileList
                builder.addCase(getFileList.pending, (state) => {
                    state.loaders.common = true;
                    state.loaders.external = true;
                    state.errors.getError = null;
                });
                builder.addCase(getFileList.fulfilled, (state, { payload }) => {
                    const blob = new Blob([payload.data]);
                    const fileUrl = URL.createObjectURL(blob);
                    const date = new Date()
                        .toISOString()
                        .replace(".", "-")
                        .split("T")[0];
                    const link = document.createElement("a");
                    link.download = `${date}-${payload.name}`;
                    link.href = fileUrl;
                    link.click();
                    state.loaders.common = false;
                    state.loaders.external = false;
                });
                builder.addCase(getFileList.rejected, (state, action) => {
                    state.loaders.common = false;
                    state.errors.getError =
                    action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
                    state.loaders.external = false;
                });

        //patchEdlList
        builder.addCase(patchEdlList.pending, (state) => {
            state.loaders.delete = true;
            state.errors.getError = null;
        });
        builder.addCase(patchEdlList.fulfilled, (state, { payload }) => {
            state.success.activate = payload;
            state.loaders.delete = false;
            state.errors.getError = null;
        });
        builder.addCase(patchEdlList.rejected, (state, action) => {
            state.loaders.delete = false;
            state.success.activate = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //checkEdlListValidators
        builder.addCase(checkEdlListValidators.pending, (state) => {
            state.loaders.validatorsLoader = true;
            state.errors.validatorsError = null;
        });
        builder.addCase(checkEdlListValidators.fulfilled, (state, { payload }) => {
            state.loaders.validatorsLoader = false;
            state.errors.validatorsError = null;
            state.success.validatorsSuccess = true;
            state.validatorsData = payload
        });
        builder.addCase(checkEdlListValidators.rejected, (state, action) => {
            state.loaders.validatorsLoader = false;
            state.errors.validatorsError=
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //applyEdlListValidators
        builder.addCase(applyEdlListValidators.pending, (state) => {
            state.loaders.validatorsLoader = true;
            state.errors.validatorsError = null;
        });
        builder.addCase(applyEdlListValidators.fulfilled, (state, { payload }) => {
            state.loaders.validatorsLoader = false;
            state.errors.validatorsError = null;
            state.success.validatorsApplySuccess = true;
            //state.validatorsData = payload
        });
        builder.addCase(applyEdlListValidators.rejected, (state, action) => {
            state.loaders.validatorsLoader = false;
            state.errors.validatorsError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //deleteEdlList
        builder.addCase(deleteEdlList.pending, (state) => {
            state.loaders.delete = true;
            state.success.deleteList = false;
        });
        builder.addCase(deleteEdlList.fulfilled, (state, { payload }) => {
            state.success.deleteList = true;
            state.loaders.delete = false;
            state.errors.addError = null;
        });
        builder.addCase(deleteEdlList.rejected, (state, action) => {
            state.loaders.delete = false;
            state.success.deleteList = false;
            state.errors.addError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //getEdlUser
        builder.addCase(getEdlUser.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getEdlUser.fulfilled, (state, { payload }) => {
            state.edlDataUser = payload;
            state.loaders.common = false;
       
        });
        builder.addCase(getEdlUser.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //getEdlDetailInstance
        builder.addCase(getEdlDetailInstance.pending, (state) => {
            state.loaders.instance = true;
        });
        builder.addCase(
            getEdlDetailInstance.fulfilled,
            (state, { payload }) => {
                state.edlDataInstance = payload;
                state.loaders.instance = false;
                state.errors.getError = null;
            }
        );
        builder.addCase(getEdlDetailInstance.rejected, (state, action) => {
            state.loaders.instance = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //getEdlDetailList
        builder.addCase(getEdlDetailList.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getEdlDetailList.fulfilled, (state, { payload }) => {
            state.edlDataDetailList = payload;
            state.loaders.common = false;
        });
        builder.addCase(getEdlDetailList.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //addEdlInstance
        builder.addCase(addEdlInstance.pending, (state) => {
            state.success.add = false;
            state.loaders.common = true;
        });
        builder.addCase(addEdlInstance.fulfilled, (state, { payload }) => {
            
            if (payload?.duplicates?.length> 0) state.errors.addErrorInstance = 'Запись уже есть в списке!';
             else if(payload?.errors?.length> 0) state.errors.addErrorInstance = payload?.errors[0]?.message;
             else {state.success.addList = true;state.errors.addErrorInstance = null;state.edlDataInstance = null;}
            if (payload?.message) {state.success.addList = payload?.message;state.errors.addErrorInstance = null;state.edlDataInstance = null;}
            state.loaders.common = false;
        });
        builder.addCase(addEdlInstance.rejected, (state, action) => {
            state.loaders.common = false;
            state.success.add = false;
            state.errors.addErrorInstance =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //deleteEdlInstance
        builder.addCase(deleteEdlInstance.pending, (state) => {
            state.loaders.common = true;
            state.success.delete = false;
        });
        builder.addCase(deleteEdlInstance.fulfilled, (state, { payload }) => {
            // state.edlDataLists = payload;
            state.success.delete = true;
            state.loaders.common = false;
            state.errors.delete = null;
        });
        builder.addCase(deleteEdlInstance.rejected, (state, action) => {
            state.loaders.common = false;
            state.success.delete = false;
            state.errors.delete =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //patchEdlInstance
        builder.addCase(patchEdlInstance.pending, (state) => {
            state.loaders.patchUser = true;
            state.success.patch = false;
            state.errors.patchError = null;
        });
        builder.addCase(patchEdlInstance.fulfilled, (state, { payload }) => {
            // state.edlDataLists = payload;
            state.success.patch = true;
            state.loaders.patchUser = false;
            state.errors.patchError = null;
        });
        builder.addCase(patchEdlInstance.rejected, (state, action) => {
            state.loaders.patchUser = false;
            state.success.patch = false;
            state.errors.patchError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //patchEdlUser
        builder.addCase(patchEdlUser.pending, (state) => {
            state.loaders.patchUser = true;
            state.success.patchUser = false;
        });
        builder.addCase(patchEdlUser.fulfilled, (state, { payload }) => {
            // state.edlDataLists = payload;
            state.success.patchUser = true;
            state.loaders.patchUser = false;
            state.errors.patchUser = null;
        });
        builder.addCase(patchEdlUser.rejected, (state, action) => {
            state.loaders.patchUser = false;
            state.success.patchUser = false;
            state.errors.patchUser =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //getTaskList
        builder.addCase(getTaskList.pending, (state) => {
            state.loaders.list = true;
            state.success.list = false;
        });
        builder.addCase(getTaskList.fulfilled, (state, { payload }) => {
            state.taskList = payload.results;
            state.countTaskList = payload.count;
            state.success.list = true;
            state.loaders.list = false;
            state.errors.list = null;
        });
        builder.addCase(getTaskList.rejected, (state, action) => {
            state.loaders.list = false;
            state.success.list = false;
            state.errors.list =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //getTaskDetails
        builder.addCase(getTaskDetails.pending, (state) => {
            state.loaders.details = true;
            state.success.details = false;
        });
        builder.addCase(getTaskDetails.fulfilled, (state, { payload }) => {
            state.taskDetails = payload;
            state.success.details = true;
            state.loaders.details = false;
            state.errors.details = null;
        });
        builder.addCase(getTaskDetails.rejected, (state, action) => {
            state.loaders.details = false;
            state.success.details = false;
            state.errors.details =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //getCheckListName
        builder.addCase(getCheckListName.pending, (state) => {
            // state.loaders.add = true;
            // state.success.patchUser = false;
        });
        builder.addCase(getCheckListName.fulfilled, (state, { payload }) => {
            // state.loaders.add = false;
        });
        builder.addCase(getCheckListName.rejected, (state, action) => {
            // state.loaders.add = false;
            state.errors.addError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });

        //getCheckListsLimit
        builder.addCase(getCheckListsLimit.pending, (state) => {
            state.loaders.add = true;
        });
        builder.addCase(getCheckListsLimit.fulfilled, (state, { payload }) => {
            state.loaders.add = false;
        });
        builder.addCase(getCheckListsLimit.rejected, (state, action) => {
            state.loaders.add = false;
            state.errors.addError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        // getCheckListsInstance
        builder.addCase(getCheckListsInstance.pending, (state) => {
            state.loaders.add = true;
        });
        builder.addCase(
            getCheckListsInstance.fulfilled,
            (state, { payload }) => {
                state.loaders.add = false;
                state.errors.addErrorInstance = null;
            }
        );
        builder.addCase(getCheckListsInstance.rejected, (state, action) => {
            state.loaders.add = false;
            state.errors.addErrorInstance =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        // getCheckCloseInstance
        builder.addCase(getCheckCloseInstance.pending, (state) => {
            state.loaders.add = true;
        });
        builder.addCase(
            getCheckCloseInstance.fulfilled,
            (state, { payload }) => {
                state.loaders.add = false;
                state.errors.getTaskError = null;
            }
        );
        builder.addCase(getCheckCloseInstance.rejected, (state, action) => {
            state.loaders.add = false;

            state.errors.getTaskError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //getCheckPlatformsLimit
        builder.addCase(getCheckPlatformsLimit.pending, (state) => {
            state.loaders.common = true;
            state.edlPlatformLimitWarning = null;
        });
        builder.addCase(
            getCheckPlatformsLimit.fulfilled,
            (state, { payload }) => {
                state.loaders.common = false;
                state.edlPlatformLimitWarning = payload;
            }
        );
        builder.addCase(getCheckPlatformsLimit.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //getExternalList
        builder.addCase(getExternalList.pending, (state) => {
            state.loaders.external = true;
        });
        builder.addCase(getExternalList.fulfilled, (state, { payload }) => {
            state.externalData = payload;
            state.loaders.external = false;
            state.errors.getError = null;
        });
        builder.addCase(getExternalList.rejected, (state, action) => {
            state.loaders.external = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
        //addExternalList
        builder.addCase(addExternalList.pending, (state) => {
            state.loaders.external = true;
            state.errors.getError = null;
        });
        builder.addCase(addExternalList.fulfilled, (state, { payload }) => {
            // state.externalData = payload;
            state.loaders.external = false;
            state.errors.getError = null;
            state.success.external = !state.success.external;
        });
        builder.addCase(addExternalList.rejected, (state, action) => {
            state.loaders.external = false;
            state.errors.getError =
            action?.payload?.detail || action?.payload?.message || 'Ошибка сервера';
        });
    },
});
export const {
    switchPatchSuccess,
    switchDeleteSuccess,
    switchAddSuccess,
    switchAddListSuccess,
    clearEdlErrors,
    switchAсtivateListSuccess,
    setProgress,
    switchDeleteListSuccess,
    switchValidateSuccess,
    switchValidateApplySuccess,
    resetValidatorsError
} = edlSlice.actions;
export default edlSlice.reducer;
