export const getEdlDataLists = ({ edl }) => edl.edlDataLists;
export const getEdlDataInstance = ({ edl }) => edl.edlDataInstance;
export const getEdlDataDetailList = ({ edl }) => edl.edlDataDetailList;
export const getEdlDataUser = ({ edl }) => edl.edlDataUser;
export const getEdlCheckErrors = ({ edl }) => edl.errors.getError;
export const getEdlTaskCheckErrors = ({ edl }) => edl.errors.getTaskError;
export const getEdlAddErrors = ({ edl }) => edl.errors.addError;
export const getEdlAddInstanceErrors = ({ edl }) => edl.errors.addErrorInstance;
export const getEdlPatchErrors = ({ edl }) => edl.errors.patchError;
export const getEdlLoaders = ({ edl }) => edl.loaders.common;
export const getEdlSuccessDelete = ({ edl }) => edl.success.delete;
export const getEdlSuccessPatch = ({ edl }) => edl.success.patch;
export const getEdlSuccessAdd = ({ edl }) => edl.success.add;
export const getEdlSuccessPatchUser = ({ edl }) => edl.success.patchUser;
export const getEdlSuccessAddList = ({ edl }) => edl.success.addList;
export const getEdlSuccessActivateList = ({ edl }) => edl.success.activate;
export const getEdlSuccessDeleteList = ({ edl }) => edl.success.deleteList;
export const getEdlPatchUserErrors = ({ edl }) => edl.errors.patchUser;
export const getDateTaskList = ({ edl }) => edl.taskList;
export const getCountTaskList = ({ edl }) => edl.countTaskList;
export const getDateTaskDetails = ({ edl }) => edl.taskDetails;

export const getFileProgress = ({ edl }) => edl.progress;
export const getEdlAddLoaders = ({ edl }) => edl.loaders.add;
export const getEdlDeleteLoaders = ({ edl }) => edl.loaders.delete;
export const getEdlPatchUserLoaders = ({ edl }) => edl.loaders.patchUser;
export const getDetailsLoaders = ({ edl }) => edl.loaders.details;
export const getListLoaders = ({ edl }) => edl.loaders.list;
export const getEdlWarningPlatform = ({ edl }) => edl.edlPlatformLimitWarning;
export const getExternalDataLists = ({ edl }) => edl.externalData;
export const getExternalLoaders = ({ edl }) => edl.loaders.external;
export const getEdlExternalSuccessAdd= ({ edl }) => edl.success.external;
export const getInstanceLoaders = ({ edl }) => edl.loaders.instance;
export const getEdlUserCompanyName = ({ edl }) => edl.edlDataUser?.org_name;

//validators
export const getValidatorLoaders = ({ edl }) => edl.loaders.validatorsLoader;
export const getValidatorError = ({ edl }) => edl.errors.validatorsError;
export const getValidatorData = ({ edl }) => edl.validatorsData;
export const getValidatorSuccess = ({ edl }) => edl.success.validatorsSuccess;
export const getValidatorApplySuccess = ({ edl }) => edl.success.validatorsApplySuccess;

