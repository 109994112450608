import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../auth";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
const checkFilters = (filters, page) => {
    if (!filters) filters = {};
    filters.page = page;
    return filters;
};
export const getCheckUrl = createAsyncThunk(
    "getCheckUrl",
    async (userUrl, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `api/v1/urlf/check-url/kfeed/`,
                { params: { url: userUrl } }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getUpdateCheckUrl = createAsyncThunk(
    "getUpdateCheckUrl",
    async (userUrl, { rejectWithValue }) => {
        try {
            const urlData = { url: userUrl };
            const params = [];
            params.push(urlData);

            const { data } = await axiosInstance.post(
                `api/v1/urlf/check-url/`,
                params
            );
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
export const getApiData = createAsyncThunk(
    "getApiData",
    async ({ audience__service_domain, organisation}, { rejectWithValue, dispatch }) => {
        const params = {};
        params.audience__service_domain = audience__service_domain;
        params.organisation = organisation

        try {
            const { data } = await axiosInstance.get(
                `api/v1/gateway/refresh-token/me/`,
                { params: params }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getUrlAssets = createAsyncThunk(
    "getUrlAssets",
    async ({ filters, page }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(`/api/v1/urlf/assets/`, {
                params: checkFilters(filters, page),
            });
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getUrlAssetsDropdown = createAsyncThunk(
    "getUrlAssetsDropdown",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `/api/v1/urlf/assets/serials/`
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getUrlGraph = createAsyncThunk(
    "getUrlGraph",
    async ({ filters }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `/api/v1/urlf/kfeed-stats/hits-per-day/`,
                {
                    params: checkFilters(filters, null),
                }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getUrlKfeedHits = createAsyncThunk(
    "getUrlKfeedHits",
    async ({ filters, page }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `/api/v1/urlf/kfeed-stats/hits-per-url/`,
                {
                    params: checkFilters(filters, page),
                }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getUrlKfeedDetail = createAsyncThunk(
    "getUrlKfeedDetail",
    async ({ param, value, urlKfeed }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `/api/v1/urlf/check-url/kfeed-only/`,
                param === 'mask' ? {
                    params: { mask: value, url: urlKfeed },
                }
                    : param === 'url' ? {
                        params: { url: value },
                    }
                        : {
                            params: { stats_id: value, url: urlKfeed },
                        }
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const patchKfeedMode = createAsyncThunk(
    "patchKfeedMode",
    async ({ id, fallbacks }, { rejectWithValue, dispatch }) => {
        const params = fallbacks;

        // params.updated_by = patchUser;
        try {
            const { data } = await axiosInstance.patch(
                `/api/v1/urlf/assets/${id}/set-fallbacks/`,
                params
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            return rejectWithValue(err.response.data);
        }
    }
);
export const getKfeedInfoOrganisation = createAsyncThunk(
    "getKfeedInfoOrganisation",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `/api/v1/urlf/assets/kfeed-info/`
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);


export const getFallbackLogs = createAsyncThunk(
    "getFallbackLogs",
    async ({serialNumber, page}, { rejectWithValue, dispatch }) => {
        try {
            
            const { data } = await axiosInstance.get(
                `/api/v1/urlf/kfeed/fallback-logs/`, {params:{page, equipment__serial_nr:serialNumber, limit: 10 }}
            );
            return data;
        } catch (err) {
            if (err.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

const initialState = {
    urlData: null,
    urlDataKfeedDetail: null,
    updatedUrlData: null,
    isAuthenticated: false,
    updateStatus: null,
    apiData: null,
    assetsData: null,
    assetsDropdown: null,
    graphData: null,
    kfeedHitsData: null,
    errors: null,
    kfeedErrors: null,
    snFilter: null,
    orgFallbacksInfo: null,
    fallbackLogs: null,
    loaders: {
        common: false,
        fallbackLogs: false,
    },
    kfeedSuccess: false
};

const urlFilterSlice = createSlice({
    name: "urlfilter",
    initialState,
    reducers: {
        resetUrlData: (state) => {
            state.urlData = null;
        },
        resetUpdUrlData: (state) => {
            state.updatedUrlData = null;
        },
        setSnFilterUrl: (state, { payload }) => {
            state.snFilter = payload;
        },
        switchKfeedSuccess: (state) => {
            state.kfeedSuccess = false;
        },
        resetKfeedErrors: (state) => {
            state.kfeedErrors = null;
        }
    },

    extraReducers: (builder) => {
        //getCheckUrl
        builder.addCase(getCheckUrl.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getCheckUrl.fulfilled, (state, { payload }) => {
            state.urlData = payload;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getCheckUrl.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'

        });

        //getUpdateCheckUrl
        builder.addCase(getUpdateCheckUrl.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getUpdateCheckUrl.fulfilled, (state, { payload }) => {
            // state.urlData = null;

            if (payload.rows[0].status === "updated") {
                state.updatedUrlData = payload.rows[0].new_cat;
                state.updateStatus = "Изменено";
            } else if (payload.rows[0].status === "no_changes") {
                state.updatedUrlData = payload.rows[0].cat;
                state.updateStatus = "Без изменений";
            } else if (payload.rows[0].status === "not_found") {
                state.updatedUrlData = payload.rows[0].cat;
                state.updateStatus = "Не найдено";
            }

            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getUpdateCheckUrl.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getApiData
        builder.addCase(getApiData.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getApiData.fulfilled, (state, { payload }) => {
            state.apiData = payload;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getApiData.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getUrlAssets
        builder.addCase(getUrlAssets.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getUrlAssets.fulfilled, (state, { payload }) => {
            state.assetsData = payload;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getUrlAssets.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getUrlAssetsDropdown
        builder.addCase(getUrlAssetsDropdown.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(
            getUrlAssetsDropdown.fulfilled,
            (state, { payload }) => {
                state.assetsDropdown = payload;
                state.loaders.common = false;
                state.errors = null;
            }
        );
        builder.addCase(getUrlAssetsDropdown.rejected, (state, action) => {
            state.loaders.common = false;

            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getUrlGraph
        builder.addCase(getUrlGraph.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getUrlGraph.fulfilled, (state, { payload }) => {
            state.graphData = payload;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getUrlGraph.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getUrlKfeedHits
        builder.addCase(getUrlKfeedHits.pending, (state) => {
            state.loaders.common = true;
            // state.kfeedHitsData = null;
        });
        builder.addCase(getUrlKfeedHits.fulfilled, (state, { payload }) => {
            state.kfeedHitsData = payload;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getUrlKfeedHits.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getUrlKfeedHits
        builder.addCase(getUrlKfeedDetail.pending, (state) => {
            state.loaders.common = true;
            state.urlDataKfeedDetail = null;
        });
        builder.addCase(getUrlKfeedDetail.fulfilled, (state, { payload }) => {
            state.urlDataKfeedDetail = payload;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getUrlKfeedDetail.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //patchKfeedMode
        builder.addCase(patchKfeedMode.pending, (state) => {
            state.loaders.common = true;
            state.kfeedErrors = null;
        });
        builder.addCase(patchKfeedMode.fulfilled, (state, { payload }) => {
            state.kfeedSuccess = true;
            state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(patchKfeedMode.rejected, (state, action) => {
            state.loaders.common = false;
            state.kfeedErrors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
        //getKfeedInfoOrganisation 
        builder.addCase(getKfeedInfoOrganisation.pending, (state) => {
            // state.loaders.common = true;
        });
        builder.addCase(getKfeedInfoOrganisation.fulfilled, (state, { payload }) => {
            state.orgFallbacksInfo = payload;
            // state.loaders.common = false;
            state.errors = null;
        });
        builder.addCase(getKfeedInfoOrganisation.rejected, (state, action) => {
            // state.loaders.common = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });

        //getFallbackLogs
        builder.addCase(getFallbackLogs.pending, (state) => {
            state.loaders.fallbackLogs = true;
        
        });
        builder.addCase(getFallbackLogs.fulfilled, (state, { payload }) => {
            state.fallbackLogs = payload;
            state.errors = null;
            state.loaders.fallbackLogs = false;
        });
        builder.addCase(getFallbackLogs.rejected, (state, action) => {
            state.loaders.fallbackLogs = false;
            state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || 'Неизвестная ошибка'
        });
    },
});
export const {
    resetUrlData,
    resetUpdUrlData,
    setSnFilterUrl,
    switchKfeedSuccess,
    resetKfeedErrors
} = urlFilterSlice.actions;
export default urlFilterSlice.reducer;
