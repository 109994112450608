import React, { useEffect } from 'react'
import styles from './Sandbox.module.css'
import { IconCopy } from '../../icons';
import { getUrlData, getIpData, getCategoryErrors } from '../../store/sandbox/selectors';
import { IconFileDownload } from '../../icons/IconFileDownload';
import { getCheckUrl, getCheckIp } from '../../store/sandbox';
import { getDocFileSandBox } from '../../store/sandbox';
import { Table, message, Badge, Row, Col, Typography, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { getIpLoader, getUrlLoader } from '../../store/sandbox/selectors';
import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import { IconErrorHash } from '../../icons/IconErrorHash';
const { Text, Title } = Typography
export default function FileInfo({ detailsData, tableData }) {
    const dispatch = useDispatch()
    const urlLoader = useSelector(getUrlLoader)
    const ipLoader = useSelector(getIpLoader)
    const urlData = useSelector(getUrlData)
    const ipData = useSelector(getIpData)
    const categoryErrors = useSelector(getCategoryErrors)
    useEffect(() => {
        if (detailsData?.file_url && detailsData?.file_url !== 'unknown') {
            dispatch(getCheckUrl(detailsData?.file_url))
        }
        if (detailsData?.source_ip && detailsData?.source_ip !== 'unknown') {
            dispatch(getCheckIp(detailsData?.source_ip?.split(':')[0]))
        }
    }, [])

    const detailItemStyle = { padding: '8px 0px 8px 0px', fontWeight: 400 }
    const [messageApi, contextHolder] = message.useMessage();
    const copyBufferTextTable = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                messageApi.open({
                    type: 'info',
                    content: 'Скопировано!',
                    style: {
                        marginTop: '10vh',
                    },
                    duration: 2
                });
            })
            .catch(() => {
                messageApi.open({
                    type: 'error',
                    content: 'Ошибка',
                    style: {
                        marginTop: '10vh',
                    },
                    duration: 2
                });
            });
    };

    function createUrlIpBadge(categories) {
        return categories?.map((category) => {

            if (category?.severity === 2) {
                return (
                    <Badge key={category?.cat_id} style={{
                        borderRadius: '2px',
                        color: '#EB5757',
                        fontSize: 12
                    }} count={category.name?.toUpperCase()} showZero color='#FFECEC' />
                )
            }
            else if (category?.severity === 0) {
                return (
                    <Badge key={category?.cat_id} style={{
                        borderRadius: '2px',
                        color: '#219653',
                        fontSize: 12
                    }} count={category?.name?.toUpperCase()} showZero color='#E0F6EA' />
                )
            }
            else if (category?.severity === 1) {
                return (
                    <Badge key={category?.cat_id} style={{
                        borderRadius: '2px',
                        color: '#E39800',
                        fontSize: 12
                    }} count={category?.name?.toUpperCase()} showZero color='#FFF9EC' />
                )
            }

            return null;


        })
    }

    const parseValue = (key, value) => {
        if (key === 'first_seen' || key === 'last_seen') {
            const date = (value.split('T')[0]).split('-').reverse()
            const time = (value.split('T')[1]).split('Z')[0]
            return (
                <div>
                    <Text style={{ color: '#4B4F55' }}>{`${date[0]}.${date[1]}.${date[2]} `}</Text>
                    <Text style={{ color: '#7A7D86' }}>{time.split('.')[0]}</Text>
                </div>
            )
        }
        else if (key === 'sha256') {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: '100%', width: '90%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap', color: '#4B4F55' }}>{`${value} `}</div>
                    <Button
                        size="small"
                        type="text"
                        icon={<IconCopy style={{ width: 16, height: 16, color: "#8090B8" }} />}
                        onClick={() => {
                            copyBufferTextTable(value);
                        }}
                    />
                </div>
            )
        }
        else if (key === 'verdict') {
            const href = `https://www.virustotal.com/gui/file/${detailsData?.sha256}`
            return (
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                    {viewVerdictBadge(value, 'parus')}
                    {detailsData?.verdict === 'Malware' && <a href={href} style={{ textDecoration: 'none' }} target='_blank'>открыть VirusTotal</a>}
                </div>

            )
        }
        else if (key === 'file_name') {
            return (
                <div style={{ height: '100%', width: '90%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap', color: '#4B4F55' }}>
                    <Text style={{ color: '#4B4F55' }}>{`${value} `}</Text>
                </div>
            )
        }
        else if (key === 'file_url') {
            return (

                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {urlLoader && <PreloaderNew />}
                    <div style={{
                        display: 'flex', opacity: urlLoader ? 0.2 : 1, alignItems: 'center', height: '100%',
                        minHeight: urlLoader ? 80 : 0, width: '100%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap'
                    }}>
                        <Text style={{ color: '#4B4F55' }}>{`${value} `}</Text>
                        <Button
                            size="small"
                            type="text"
                            icon={value !== 'unknown' ? <IconCopy style={{ width: 16, height: 16, color: "#8090B8" }} /> : null}
                            onClick={() => {
                                copyBufferTextTable(value);
                            }}
                        />
                    </div>
                    {categoryErrors && value && value !== 'unknown' && <Text style={{ color: '#EB5757' }}>{categoryErrors}</Text>}
                    {urlData && <div style={{ display: 'flex', gap: 5 }}>
                        {createUrlIpBadge(urlData?.categories)}
                        {urlData?.kfeed_results?.length > 0 &&
                            <Badge key='kfeed-url' style={{
                                borderRadius: '2px',
                                color: '#EB5757',
                                fontSize: 12
                            }} count={'kfeed'.toUpperCase()} showZero color='#FFECEC' />}
                    </div>}
                </div>
            )
        }
        else if (key === 'source_ip') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {ipLoader && <PreloaderNew />}
                    <div style={{
                        display: 'flex', opacity: ipLoader ? 0.2 : 1, alignItems: 'center', height: '100%',
                        minHeight: ipLoader ? 80 : 0, width: '100%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap'
                    }}>
                        <Text style={{ color: '#4B4F55' }}>{`${value} `}</Text>
                        <Button
                            size="small"
                            type="text"
                            icon={value !== 'unknown' ? <IconCopy style={{ width: 16, height: 16, color: "#8090B8" }} /> : null}
                            onClick={() => {
                                copyBufferTextTable(value);
                            }}
                        />
                    </div>
                    {categoryErrors && value && value !== 'unknown' &&
                        <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', gap: 8 }}>
                            <IconErrorHash />
                            <Text style={{ color: '#EB5757' }}>{categoryErrors}</Text>
                        </div>}
                    {ipData && <div style={{ display: 'flex', gap: 5 }}>
                        {createUrlIpBadge(ipData?.categories)}
                        {ipData?.kfeed_results?.length > 0 &&
                            <Badge key='kfeed-ip' style={{
                                borderRadius: '2px',
                                color: '#EB5757',
                                fontSize: 12
                            }} count={'kfeed'.toUpperCase()} showZero color='#FFECEC' />}
                    </div>}
                </div>
            )
        }
        else if (key === 'file_size') {
            return (
                <Text style={{ color: '#4B4F55' }}>{`${value || 0} байт`}</Text>
            )
        }
        else {
            return (
                <Text style={{ color: '#4B4F55' }}>{value}</Text>
            )
        }
    }

    const changeKeyToTitle = (key) => {
        switch (key) {
            case 'sha256':
                return 'sha 256';
            case 'verdict':
                return 'Parus verdict';
            case 'first_seen':
                return 'First seen (UTC)';
            case 'last_seen':
                return 'Last seen (UTC)';
            case 'hits':
                return 'Hits';
            case 'file_size':
                return 'File size';
            case 'file_type':
                return 'File type';
            case 'file_name':
                return 'File name';
            case 'file_url':
                return 'Source URL';
            case 'source_ip':
                return 'Source IP';
            default:
                break;
        }

    }


    const viewVerdictBadge = (verdict, parus) => {
        switch (verdict) {
            case "Malware":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#EB5757',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#FFECEC' />
                )
            case "Clean":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#219653',
                        fontSize: 12
                    }} count={parus === 'parus' ? 'benign'.toUpperCase() : verdict?.toUpperCase()} showZero color='#E0F6EA' />
                )
            case "Grayware":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#E39800',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#FFF9EC' />
                )
            case "Not applicable":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#7a2300',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#ff7a45' />
                )
            case "Adware and other":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#cc7810',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
                )
            case "Not categorized":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: 'grey',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#f8f8ff' />
                )
            case null:
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: 'grey',
                        fontSize: 12
                    }} count={parus === 'parus' ? 'unknown'.toUpperCase() : ''} showZero color='#f8f4ff' />
                )
            case "Unknown":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: 'grey',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#f8f8ff' />
                )
            case "NotAVirus":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#cc7810',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
                )
            case "Benign":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#219653',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#E0F6EA' />
                )
            case "Unsupported":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#cc7810',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
                )
            case "Error":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#12020c',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#9e1068' />
                )
            case "Low Risk":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#cc7810',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
                )
            case "Coming soon":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        borderColor: 'darkgray',
                        color: 'darkgray',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='white' />
                )
            case "Medium Risk":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#E39800',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#FFF9EC' />
                )
            case "High Risk":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#EB5757',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#FFECEC' />
                )
            case "Critical Risk":
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#EB5757',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#FFECEC' />
                )
            default:
                return (
                    <Badge style={{
                        borderRadius: '2px',
                        color: '#EB5757',
                        fontSize: 12
                    }} count={verdict?.toUpperCase()} showZero color='#FFECEC' />
                )
        }
    };
    const tableDataset = [
        {
            source: 'Palo Alto Networks',
            verdict: tableData?.verdict_palo_alto,
            finished_at: tableData?.finished_palo_alto_at,
            details: tableData?.details_palo_alto,
            report: tableData?.report_palo_alto

        },
        {
            source: 'Fortinet',
            verdict: tableData?.verdict_fortisandbox,
            finished_at: tableData?.finished_fortisandbox_at,
            details: tableData?.details_fortisandbox,
            report: tableData?.report_fortisandbox

        },
        {
            source: 'Kaspersky',
            verdict: tableData?.verdict_kaspersky,
            finished_at: tableData?.finished_kaspersky_at,
            details: tableData?.details_kaspersky,
            report: tableData?.report_kaspersky
        },
        {
            source: 'CheckPoint',
            verdict: tableData?.verdict_checkpoint,
            finished_at: tableData?.finished_checkpoint_at,
            details: tableData?.details_checkpoint,
            report: null
        },
        {
            source: 'ClamAV',
            verdict: tableData?.verdict_clamav,
            finished_at: tableData?.finished_clamav_at,
            details: tableData?.details_clamav,
            report: null
        }
    ]
    const checkTableColumns = [
        createColumn('Источник', 'source', '15%', (text) => { return (<Text >{text}</Text>) }),
        createColumn('Вердикт', 'verdict', '15%', (text, row) => {
            if (text != null) {
                if (row.source === 'Palo Alto Networks' && row.verdict === 'Unsupported') {
                    return (viewVerdictBadge('Unknown'))
                }
                return (viewVerdictBadge(text))
            }
        }),
        createColumn('Дата вердикта', 'finished_at', '15%', (text) => { if (text != null) return (<Text >{parseValue('last_seen', text)}</Text>) }),
        createColumn('Детали', 'details', '40%', (text) => {
            return (text ?
                text?.split("\n")?.map((item) => (
                    <div>{item}</div>))
                : " - ")
        }),
        createColumn('Отчет', 'report', '15%', (text, row) => { if (text) return (<IconFileDownload onClick={() => dispatch(getDocFileSandBox({ hash: detailsData?.sha256, source: row?.source === 'Palo Alto Networks' ? 'palo-alto' : 'forti' }))} />) }),
    ]
    function createColumn(title, key, width, render) {
        return { title, key, dataIndex: key, width, render };
    }
    return (
        <div>
            {contextHolder}
            <Row>
                <Title style={{ color: '#333333', margin: '14px 0px 14px 0px' }} level={5}>Информация по файлу</Title>
            </Row>
            <Row >
                <Col span={12} >
                    {Object.keys(detailsData).map(key => {
                        if (key !== 'uid' && key !== 'file_name' && key !== 'file_url' && key !== 'source_ip') {
                            return (
                                detailsData[key] !== null && (
                                    <Row style={detailItemStyle} key={key}>
                                        <Col span={9} style={{ color: '#7A7D86', paddingLeft: '24px' }}>
                                            {changeKeyToTitle(key)}
                                        </Col>
                                        <Col span={15} style={{ display: 'flex', alignItems: 'center', color: '#4B4F55' }}>
                                            {parseValue(key, detailsData[key])}
                                        </Col>
                                    </Row>)
                            )
                        }
                    })}
                </Col>
                <Col span={12}>
                    {Object.keys(detailsData).map(key => {
                        if (key === 'file_name' || key === 'file_url' || key === 'source_ip') {
                            return (
                                detailsData[key] !== null && (
                                    <Row style={detailItemStyle} key={key}>
                                        <Col span={9} style={{ color: '#7A7D86', paddingLeft: '24px' }}>
                                            {changeKeyToTitle(key)}
                                        </Col>
                                        <Col span={15} style={{ display: 'flex', alignItems: 'center', color: '#4B4F55' }}>
                                            {parseValue(key, detailsData[key])}
                                        </Col>
                                    </Row>)
                            )
                        }
                    })}
                </Col>
            </Row>
            <Row>
                <Title style={{ color: '#333333', margin: '14px 0px 14px 0px' }} level={5}>Таблица проверок</Title>
            </Row>
            <Table
                style={{
                    fontWeight: 400
                }}
                className={styles.table}
                columns={checkTableColumns}
                dataSource={tableDataset}
                pagination={false}
                rowKey={(obj) => obj?.source + 'detailsHash'}
            /></div>
    )
}