import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../auth";
const typeFileApp = (value) => {
  let type = "";
  switch (value) {
    case "txt":
      type = "";
      break;
    case "zip":
      type = "application/zip";
      break;
    case "xlsx":
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
      break;
    case "xls":
      type = "application/vnd.ms-excel";
      break;
    case "doc":
      type = "application/msword";
      break;
    case "docx":
      type =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    default:
      type = `application/${value}`;
  }
  return type;
};
const checkFilters = (filters, page) => {
  if (!filters) filters = {};
  filters.page = page;
  return filters;
};
export const getSandboxList = createAsyncThunk(
  "getSandboxList",
  async ({ filters, page, pagination }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/sandbox/checked-files-data/`,
        { params: checkFilters(filters, page) }
      );
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

// export const getSandboxStats = createAsyncThunk(
//   "getSandboxStatsl",
//   async (_, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosInstance.get(
//         `api/v1/sandbox/checked-files-metrics/`
//       );
//       return data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );
export const getSandboxRequestDetails = createAsyncThunk(
  "getSandboxRequestDetails",
  async (uid, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/v1/sandbox/requests/${uid}/details/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getSandboxMyAssets = createAsyncThunk(
  "getSandboxMyAssets",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/sandbox/my-assets/`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getDocFileSandBox = createAsyncThunk(
  "getDocFileSandBox",
  async ({ hash, source }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/sandbox/reports/${source}/${hash}/`, {

        responseType: "blob",
      });
      return { data, source, hash };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 404)
        return rejectWithValue({
          message: "К сожалению запрашиваемый отчет больше не существует",
        });

      return rejectWithValue(err.response.data);
    }
  }
);

export const getSandboxOverallStatistics = createAsyncThunk(
  "getSandboxOverallStatistics",
  async ({ filters }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/v1/sandbox/overall-statistics/`,
        { params: filters }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getGraphsData = createAsyncThunk(
  "getGraphsData",
  async ({ filters }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/v1/sandbox/last-month-statistics/`,
        { params: filters }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSandboxRequestsTableData = createAsyncThunk(
  "getSandboxRequestsTableData",
  async ({ filters }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/sandbox/requests/`,
        { params: filters }
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getSandboxHashDetailsData = createAsyncThunk(
  "getSandboxHashDetailsData",
  async ({ hash }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/sandbox/hash/`,
        { params: { sha256: hash } }
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 404)
        return rejectWithValue({
          details: "Хеш не найден",
        });
      else if (err.response.status === 422)
        return rejectWithValue({
          details: "Невалидный хеш",
        });
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getCheckUrl = createAsyncThunk(
  "getCheckUrl",
  async (userUrl, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/v1/urlf/check-url/kfeed/`,
        { params: { url: userUrl } }
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 429) return rejectWithValue({
        details: 'Превышен лимит запросов'
      })
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getCheckIp = createAsyncThunk(
  "getCheckIp",
  async (userIp, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/v1/urlf/check-url/kfeed/`,
        { params: { url: userIp } }
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 429) return rejectWithValue({
        details: 'Превышен лимит запросов'
      })
      else return rejectWithValue(err.response.data);
    }
  }
);


export const getMalwareSampleSandBox = createAsyncThunk(
  "getMalwareSampleSandBox",
  async ( uid, { rejectWithValue, dispatch }) => {
    try {
      console.log(uid)
      const { data } = await axiosInstance.get(`api/v1/sandbox/samples/${uid}/`, {
        responseType: "blob",
      });
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 404)
        return rejectWithValue({
          message: "К сожалению запрашиваемый архив не существует",
        });

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendFileToCheck = createAsyncThunk(
  "sendFileToCheck",
  async ({ file, orgId_userId }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('client_id', orgId_userId)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percent = Math.floor((current * 100) / total);
          dispatch(fileUploadProgress(percent));
        },
      };

      const { data } = await axiosInstance.post(
        "api/v1/sandbox/upload-file/",
        formData,
        config
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else if (err.response.status === 422) return rejectWithValue({
        details: 'Файл не соответствует требованиям'
      })
      else if (err.response.status === 500) return rejectWithValue({
        details: 'Ошибка загрузки, попробуйте еще раз'
      })
      else if (err.response.status === 418) return rejectWithValue({
        details: 'Неподдерживаемый тип файла'
      })
      else if (err.response.status === 403) return rejectWithValue({
        details: err.response.data.detail.message
      })
      else return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  fileChecker: {
    progress: 0,
    loading: false,
    fileUploadErrors: null,
    sha256: null,
    finished: false,
  },
  ipData: null,
  urlData: null,
  sandboxData: null,
  sandboxRequestsTableData: null,
  sandboxRequestDetails: null,
  hashCheckDetails: null,
  allFilesGraphData: null,
  suspiciousFilesGraphData: null,
  fileTypesGraphData: null,
  sandboxOverallStatistics: null,
  sandboxAssets: [],
  nextPage: null,
  fileError: null,
  sampleError: null,
  errors: null,
  hashCheckerErrors: null,
  categoryErrors: null,
  loaders: {
    common: false,
    overall: false,
    table: false,
    details: false,
    graphs: false,
    hashChecker: false,
    ipLoader: false,
    urlLoader: false,
  },
};

const sandboxSlice = createSlice({
  name: "sandbox",
  initialState,
  reducers: {
    resetHashCheckerData: (state) => {
      state.hashCheckDetails = null;
      state.hashCheckerErrors = null;
    },
    resetReportsError: (state) => {
      state.fileError = null
    },
    fileUploadProgress: (state, { payload }) => {
      state.fileChecker.progress = payload;
    },
  },

  extraReducers: (builder) => {


    //sendFileToCheck
    builder.addCase(sendFileToCheck.pending, (state) => {
      state.fileChecker.loading = true;
      state.fileChecker.finished = false;
      state.fileChecker.sha256 = null;
      state.fileChecker.progress = 0;
      state.fileChecker.fileUploadErrors = null;

      //hashCheck to null
      state.ipData = null;
      state.urlData = null;
      state.hashCheckDetails = null;
      state.fileError = null;
      state.hashCheckerErrors = null;
    });
    builder.addCase(sendFileToCheck.fulfilled, (state, { payload }) => {
      state.fileChecker.loading = false;
      state.fileChecker.sha256 = payload.sha256
      state.fileChecker.finished = payload.finished;
      state.fileChecker.fileUploadErrors = null;
    });
    builder.addCase(sendFileToCheck.rejected, (state, action) => {
      state.fileChecker.loading = false;
      state.fileChecker.progress = 0;
      state.fileChecker.finished = false;
      state.fileChecker.sha256 = null;
      state.fileChecker.fileUploadErrors =
        action?.payload?.details || action?.payload?.error || action?.payload?.message || "Ошибка сервера";
    });


    //getCheckUrl
    builder.addCase(getCheckUrl.pending, (state) => {
      state.categoryErrors = null;
      state.loaders.urlLoader = true;
      state.urlData = null;
    });
    builder.addCase(getCheckUrl.fulfilled, (state, { payload }) => {
      state.urlData = payload;
      state.loaders.urlLoader = false;
      state.categoryErrors = null;
    });
    builder.addCase(getCheckUrl.rejected, (state, action) => {
      state.loaders.urlLoader = false;
      state.categoryErrors =
        action?.payload?.details || action?.payload?.error || action?.payload?.message || "Ошибка сервера";
    });

    //getCheckIp
    builder.addCase(getCheckIp.pending, (state) => {
      state.categoryErrors = null;
      state.ipData = null;
      state.loaders.ipLoader = true;
    });
    builder.addCase(getCheckIp.fulfilled, (state, { payload }) => {
      state.ipData = payload;
      state.loaders.ipLoader = false;
      state.categoryErrors = null;
    });
    builder.addCase(getCheckIp.rejected, (state, action) => {
      state.loaders.ipLoader = false;
      state.categoryErrors =
        action?.payload?.details || action?.payload?.error || action?.payload?.message || "Ошибка сервера";
    });

    //getSandboxOverallStatistics
    builder.addCase(getSandboxOverallStatistics.pending, (state) => {
      state.errors = null;
      state.loaders.overall = true;

    });
    builder.addCase(getSandboxOverallStatistics.fulfilled, (state, { payload }) => {
      state.sandboxOverallStatistics = payload;
      state.errors = null;
      state.loaders.overall = false;
    });
    builder.addCase(getSandboxOverallStatistics.rejected, (state, action) => {
      state.loaders.overall = false;
      state.errors =
        action?.payload?.details || action?.payload?.error || "Ошибка сервера";
    });

    //getSandboxRequestDetails
    builder.addCase(getSandboxRequestDetails.pending, (state) => {
      state.loaders.details = true;
      state.sandboxRequestDetails = null;
      state.ipData = null;
      state.urlData = null;
      state.errors = null;
    });
    builder.addCase(getSandboxRequestDetails.fulfilled, (state, { payload }) => {
      state.sandboxRequestDetails = payload;
      state.loaders.details = false;
      state.errors = null;
    });
    builder.addCase(getSandboxRequestDetails.rejected, (state, action) => {
      state.loaders.details = false;
      state.sandboxRequestDetails = null;
      state.errors =
        action?.payload?.detail || action?.payload?.error || "Ошибка сервера";
    });

    //getSandboxHashDetailsData
    builder.addCase(getSandboxHashDetailsData.pending, (state) => {
      state.loaders.hashChecker = true;
      state.ipData = null;
      state.urlData = null;
      state.hashCheckDetails = null;
      state.fileError = null;
      state.hashCheckerErrors = null;

      //fileCheck to null
      state.fileChecker.finished = false;
      state.fileChecker.sha256 = null;
      state.fileChecker.progress = 0;
      state.fileChecker.fileUploadErrors = null;

    });
    builder.addCase(getSandboxHashDetailsData.fulfilled, (state, { payload }) => {
      state.hashCheckDetails = payload;
      state.loaders.hashChecker = false;
      state.fileError = null;
      state.hashCheckerErrors = null;
    });
    builder.addCase(getSandboxHashDetailsData.rejected, (state, action) => {
      state.loaders.hashChecker = false;
      state.hashCheckDetails = null;
      state.hashCheckerErrors =
        action?.payload?.details || action?.payload?.error || "Ошибка сервера";
    });

    //getSandboxRequestsTableData
    builder.addCase(getSandboxRequestsTableData.pending, (state) => {
      state.loaders.table = true;
      state.fileError = null;
    });
    builder.addCase(getSandboxRequestsTableData.fulfilled, (state, { payload }) => {
      state.sandboxRequestsTableData = payload
      state.errors = null;
      state.loaders.table = false;
    });
    builder.addCase(getSandboxRequestsTableData.rejected, (state, action) => {
      state.loaders.table = false;
      state.errors =
        action?.payload?.detail || action?.payload?.error || "Ошибка сервера";
    });

    //getGraphsData
    builder.addCase(getGraphsData.pending, (state) => {
      state.loaders.graphs = true;
      state.errors = null;
    });
    builder.addCase(getGraphsData.fulfilled, (state, { payload }) => {
      state.allFilesGraphData = payload.checked_requests;
      state.suspiciousFilesGraphData = { grayware_requests: payload.grayware_requests, malware_requests: payload.malware_requests }
      state.fileTypesGraphData = payload.file_type_breakdown
      state.loaders.graphs = false;
      state.errors = null;
    });
    builder.addCase(getGraphsData.rejected, (state, action) => {
      state.loaders.graphs = false;
      state.errors =
        action?.payload?.detail || action?.payload?.error || "Ошибка сервера";
    });

    //getDocFileSandBox
    builder.addCase(getDocFileSandBox.pending, (state) => {
      state.loaders.details = true;
      state.fileError = null;
      // state.loaders.external = true;
      // state.errors.getError = null;
    });
    builder.addCase(getDocFileSandBox.fulfilled, (state, { payload }) => {
      const blob = new Blob([payload.data], {
        type: payload.data.type,
      });
      // const blob = new Blob([payload.data], { type: `application/${payload.typeFile}` });
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.setAttribute("href", fileUrl);
      link.setAttribute("download", `${payload?.source}-${payload?.hash} `);
      link.style.display = "none";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      state.loaders.details = false;
    });
    builder.addCase(getDocFileSandBox.rejected, (state, action) => {
      state.loaders.details = false;
      state.fileError =
        action.payload.detail || action.payload.message || "Ошибка сервера";
      // state.loaders.external = false;
    });



    //getMalwareSampleSandBox
    builder.addCase(getMalwareSampleSandBox.pending, (state) => {
    });
    builder.addCase(getMalwareSampleSandBox.fulfilled, (state, { payload }) => {
      const blob = new Blob([payload.data], { type: 'application/zip' });
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute('href', fileUrl)
      link.style.display = 'none'

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    });
    builder.addCase(getMalwareSampleSandBox.rejected, (state, action) => {
      state.errors = action?.payload?.details || action?.payload?.message || 'Ошибка сервера';
    });

    //getSandboxMyAssets
    builder.addCase(getSandboxMyAssets.pending, (state) => {
      state.loaders.common = true;
      state.fileError = null;
    });
    builder.addCase(getSandboxMyAssets.fulfilled, (state, { payload }) => {
      state.sandboxAssets = payload;
      state.loaders.common = false;
      state.errors = null;
    });
    builder.addCase(getSandboxMyAssets.rejected, (state, action) => {
      state.loaders.common = false;
      // state.errors = action.payload.detail ? action.payload.detail : action.payload.error;
    });


  },
});
export const { resetHashCheckerData, resetReportsError, fileUploadProgress } = sandboxSlice.actions;
export default sandboxSlice.reducer;
