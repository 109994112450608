import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Space, Table, Typography, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getApiData } from "../../store/urlfilter";
import { getDataApi } from "../../store/urlfilter/selectors";
import { IconCopy } from "../../icons";
import Dot from "../UI/Dot";
import { changeDate } from "./utils";
import { getUser } from "../../store/auth/selectors";
const { Text } = Typography;
const styleTextGray = { color: "#7A7D86" };

function Api() {
  const [copyText, setCopyText] = useState("");
  const dispatch = useDispatch();
  const apiData = useSelector(getDataApi);

  message.config({ duration: 5 });
  const user= useSelector(getUser);
  useEffect(() => {
    dispatch(getApiData({ audience__service_domain: "url.parus.su", organisation: user?.organisation.id  }));
  }, []);

  const columns = [
    createColumn("Активность", "is_active", (_, row) =>
      row["is_active"] ? (
        <>
          <Dot color="#219653" />
          Активен
        </>
      ) : (
        <>
          <Dot color="#EB5757" />
          Неактивен
        </>
      )
    ),
    createColumn("Дата начала", "issued_at", (text) => changeDate(text)),
    createColumn("Дата окончания", "date_end", (text) => changeDate(text)),
    createColumn("Запросы за сутки", "last_day_requests"),
    createColumn("Запросы за 30 дней", "last_month_requests"),
    createColumn("Запросов всего", "total_requests"),
    createColumn("Refresh Token", "refresh_token", (text) => (
      <Button
        size="small"
        type="text"
        icon={<IconCopy style={{ color: "#8090B8" }} />}
        onClick={() => {
          copyBufferTextTable(text);
        }}
        title={text}
      >
        <span>{copyText.text}</span>
      </Button>
    )),
  ];
  const copyBufferTextTable = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText({ text: "Copy", field: "table" });
      })
      .catch(() => {
        setCopyText({ text: "Error", field: "table" });
      });
  };
  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      {apiData?.length === 0 && (
        <Card>
          <Space
            split={<Divider type="vertical" style={{ height: 52 }} />}
            size={80}
          >
            <Space direction="vertical" size={4}>
              <Button
                type="primary"
                onClick={() =>
                  window.open(
                    "mailto:office@styletele.com?subject=Доступ к URL-F API"
                  )
                }
              >
                Запросить доступ
              </Button>
            </Space>
            <Space direction="vertical" size={4}>
              <Text style={styleTextGray}> office@styletele.com</Text>
            </Space>
          </Space>
        </Card>
      )}
      {apiData?.length > 0 && (
        <Table
          columns={columns}
          rowKey={(obj) => obj.refresh_token}
          size="small"
          pagination={false}
          style={{ overflowX: "auto" }}
          className="table"
          dataSource={apiData}
        />
      )}
    </Space>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}

export default Api;
