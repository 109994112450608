import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Modal, Typography } from "antd";
import { IconClose } from "../../icons";
import ModalChangePasswordSuccess from "./ModalChangePasswordSuccess";
import { useDispatch, useSelector } from "react-redux";
import { patchEdlUser } from "../../store/edl";
import {
  getEdlPatchUserErrors,
  getEdlPatchUserLoaders,
  getEdlSuccessPatchUser,
} from "../../store/edl/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { getEdlUserCompanyName } from "../../store/edl/selectors";
import styles from './Edlmodal.module.css'

function ModalChangePassword({ open, close }) {
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector(getEdlUserCompanyName)
  const isLoading = useSelector(getEdlPatchUserLoaders);
  const patchUserSuccess = useSelector(getEdlSuccessPatchUser);
  const errors = useSelector(getEdlPatchUserErrors);

  useEffect(() => {
    if (patchUserSuccess === true) {
      close();
      setModalSuccessOpen(true);
    }
  }, [patchUserSuccess]);

  return (
    <>
      <ModalChangePasswordSuccess
        open={modalSuccessOpen}
        close={() => {
          setModalSuccessOpen(false);
          close();
        }}
      />
      <Modal open={open} closable={false} footer={null} centered>
        <Button
          onClick={close}
          icon={<IconClose style={{ width: 12, height: 12 }} />}
          type="ghost"
          style={{
            position: "absolute",
            right: 16,
            top: 16,
            width: 32,
            height: 32,
            backgroundColor: "#F6F8FD",
            padding: 0,
            zIndex: 10,
          }}
        />
        <Form
          layout="vertical"
          onFinish={({ passwordNew }) => {
            dispatch(patchEdlUser({password: passwordNew, updated_by: company}));
          }}
          style={{ position: "relative" }}
        >
          {errors && (
            <Alert
              key={errors + "r"}
              message="Password Error"
              description={errors}
              type="error"
              showIcon
            />
          )}
          {isLoading && <PreloaderNew />}
          <Form.Item>
            <Typography.Title level={3}>Изменить пароль</Typography.Title>
          </Form.Item>
          <Form.Item name="passwordNew" label="Новый пароль">
            <Input.Password className={styles.passwordChangeInput}/>
          </Form.Item>
          <Form.Item
            name="passwordRepeat"
            label="Повторите пароль"
            dependencies={["passwordNew"]}
            rules={[
              { required: true, message: "Пожалуйста, повторите пароль!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("passwordNew") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Пароли не совпадают!"));
                },
              }),
            ]}
          >
            <Input.Password className={styles.passwordChangeInput}/>
          </Form.Item>
          <Form.Item>
            {
              <div
                style={{
                  display: "flex",
                  gap: 24,
                }}
              >
                <Button
                  onClick={close}
                  style={{ flexGrow: 1 }}
                  htmlType="reset"
                >
                  Отмена
                </Button>
                <Button
                  // onClick={() => {
                  // 	close();
                  // 	setModalSuccessOpen(true);
                  // }}
                  style={{ flexGrow: 1, background: "#2E496F" }}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading ? true : false}
                >
                  Сохранить
                </Button>
              </div>
            }
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ModalChangePassword;
